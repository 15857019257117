.maptalks-zoom { text-align: center;}
.maptalks-zoom .maptalks-zoom-zoomlevel { display: block; width: 30px; height: 30px; background: #fff; color: #868686; border: 1px solid #9d9d9d;  line-height: 26px; text-align: center; box-sizing: border-box}
.maptalks-zoom .maptalks-zoom-zoomlevel .maptalks-zoom-zoomlevel-text {display: inline-block; font-size: 12px; transform: scale(.8)}
.maptalks-zoom-slider {margin-top: 6px;}
.maptalks-zoom-slider a.maptalks-zoom-zoomin ,.maptalks-zoom-slider a.maptalks-zoom-zoomout {display: block; font-size: 16px; width: 30px; height: 30px; border: 1px solid #9d9d9d; background: #fff; line-height: 28px; text-decoration: none; background-repeat: no-repeat; background-position: center; box-sizing:border-box}
.maptalks-zoom-slider a.maptalks-zoom-zoomin { background-image: url(images/control/zoomin.svg) }
.maptalks-zoom-slider a.maptalks-zoom-zoomout { height: 29px; background-image: url(images/control/zoomout.svg); border-top: none }

.maptalks-compass { width: 30px; height: 30px; background: #fff; border: 1px solid #9d9d9d; border-radius: 50%; box-sizing: border-box; background-image: url(images/control/compass.svg); background-repeat: no-repeat; background-position: center; cursor: pointer}

.maptalks-reset { width: 30px; height: 30px; background: #fff; border: 1px solid #9d9d9d; box-sizing: border-box; background-image: url(images/control/reset.svg); background-repeat: no-repeat; background-position: center; cursor: pointer }

.maptalks-toolbar-vertical { /*border: 1px solid #3b3e44;*/margin: 0; border: 1px solid #b8b8b8; overflow : visible;}
.maptalks-toolbar-vertical ul ,.maptalks-toolbar-horizonal ul {margin: 0;padding: 0;}
.maptalks-toolbar-vertical ul li+li { border-top: 1px solid #ddd; }
.maptalks-toolbar-vertical li { text-align:center;list-style: none; line-height:28px; font-size: 12px; color:#5a5a5a; background: #fff; min-width: 10px; min-height: 28px; /*border-bottom: 1px solid #425568;*/ position: relative; padding:0px 10px;}
.maptalks-toolbar-vertical li:hover { color: #fff; background: #6a97d9;}
.maptalks-toolbar-vertical li .maptalks-dropMenu { padding: 0; position: absolute; top: -1px; overflow : visible; border: 1px solid #b8b8b8; /*border: 1px solid #3e4f61; border-right: none; border-top: none;*/}
.maptalks-toolbar-vertical li .maptalks-dropMenu li { list-style: none; min-width: 95px; background: #606976; height: 28px; }
.maptalks-toolbar-vertical li .maptalks-dropMenu li + li { border-top: 1px solid #ddd; }
.maptalks-toolbar-vertical li .maptalks-dropMenu li a { color: #fff; display: block; line-height: 27px; text-indent: 33px;  text-decoration: none; font-size: 12px;}
.maptalks-toolbar-vertical li .maptalks-dropMenu em.maptalks-ico { display: block; width: 5px; height: 6px; /*background: url(images/control/4_1.png) no-repeat;*/ position: absolute; top: 12px; right: -4px;}
.maptalks-toolbar-vertical li .maptalks-dropMenu li.maptalks-on ,.maptalks-toolbar-vertical li .maptalks-dropMenu li:hover  { background: #4b545f;}

.maptalks-toolbar-horizonal { /*border: 1px solid #3b3e44;*/margin: 0;overflow : visible;}
.maptalks-toolbar-horizonal li { text-align:left; line-height:28px; font-size: 12px; color:#5a5a5a; padding: 0px 10px; list-style: none; min-width: 28px; min-height: 28px; float: left; background: #fff; border: 1px solid #b8b8b8; /*border-right: 1px solid #425568;*/ position: relative; }
.maptalks-toolbar-horizonal ul li:not(:last-child) { border-right-color: #ddd }
.maptalks-toolbar-horizonal ul li+li { border-left: none }
.maptalks-toolbar-horizonal li:hover { color: #fff; background: #6a97d9 /*border-right:1px solid #1bbc9b;*/ }
.maptalks-toolbar-horizonal li .maptalks-dropMenu { display:block;position: absolute; left: -1px;  overflow : visible;/*border: 1px solid #3e4f61;  border-top: none;*/}
.maptalks-toolbar-horizonal li .maptalks-dropMenu li { list-style: none; min-width: 95px; background: #606976; height: 28px; border: 1px solid #b8b8b8 }
.maptalks-toolbar-horizonal li .maptalks-dropMenu ul li:first-child { border-top: none }
.maptalks-toolbar-horizonal li .maptalks-dropMenu ul li:not(:last-child) { border-bottom-color: #ddd }
.maptalks-toolbar-horizonal li .maptalks-dropMenu li+li { border-top: none }
.maptalks-toolbar-horizonal li .maptalks-dropMenu li a { color: #fff; display: block; line-height: 27px; text-indent: 20px; text-decoration: none; font-size: 12px;}
.maptalks-toolbar-horizonal li .maptalks-dropMenu em.maptalks-ico { display: block; width: 5px; height: 6px; /*background: url(images/control/4.png) no-repeat; */position: absolute; top: -4px; left: 12px;}
.maptalks-toolbar-horizonal .maptalks-dropMenu li:hover { background: #4b545f }

.maptalks-toolbar-vertical li .maptalks-dropMenu li a::before, .maptalks-toolbar-horizonal li .maptalks-dropMenu li a::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 10px;
  left: 14px;
  border-top: 4px solid transparent;
  border-left: 6px solid #fff;
  border-bottom: 4px solid transparent;
}

.maptalks-menu { background: #fff; padding: 1px; width: 172px; border: 1px solid #b4b3b3; /*box-shadow: 3px 5px 10px #dbdada;*/}
.maptalks-menu .maptalks-menu-items { color: #5a5756; margin: 0; padding: 0; font-size: 12px;}
.maptalks-menu .maptalks-menu-items li { list-style: none; height: 30px; line-height: 30px; text-indent: 16px;}
.maptalks-menu .maptalks-menu-items li:hover {background: #007fbe; color: #fff;cursor:pointer;}
.maptalks-menu .maptalks-menu-items li.maptalks-menu-splitter { list-style: none; height: 2px; background: #ddd;}

.maptalks-msgBox { background: #fff; border: 1px solid #b4b3b3; border-radius: 3px; /*box-shadow: 3px 5px 5px #dbdada;*/}
.maptalks-msgBox em.maptalks-ico {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -5px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #b4b3b3;
}
.maptalks-msgBox em.maptalks-ico::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: -7px;
  top: -10px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 9px solid #fff;
}
.maptalks-msgBox h2 { display: block; height: auto; line-height: 30px; font-weight: bold; font-size: 14px; padding: 0 10px;  margin: 0;  white-space: nowrap;padding-right: 30px;}
.maptalks-msgBox a.maptalks-close { display: block; width: 13px; height: 13px; text-decoration: none; font-size: 14px; color: #a5a5a5; font-weight: bold; position: absolute; top: 3px; right: 6px }
.maptalks-msgBox .maptalks-msgContent { font-size: 12px;padding: 10px; min-width: 200px;}

.maptalks-panel { background: #fff; border: 1px solid #b4b3b3; border-radius: 3px; /*box-shadow: 3px 5px 5px #dbdada;*/}
.maptalks-panel .maptalks-panel-content { padding: 10px; min-width: 200px; min-height: 60px;}
.maptalks-panel a.maptalks-close { display: block; width: 13px; height: 13px; text-decoration: none; font-size: 14px; color: #a5a5a5; font-weight: bold; position: absolute; top: 3px; right: 6px }

.maptalks-attribution {display: inline-block;opacity: 1; color: #aeaeae; background:#fff; background-color: rgba(255,255,255,0.7);padding:0px;font-size:13px;font-family: "microsoft yahei","Helvetica Neue", Helvetica, sans-serif;}
.maptalks-attribution a {text-decoration: none; color : #8eafbe;}
.maptalks-attribution a:hover {text-decoration: underline;}

.maptalks-overview {background:#fff; border:1px solid #b4b3b3; width:100%;height:100%;}
.maptalks-overview-button {
  cursor:pointer;
  background:#fff;
  width:18px;
  height:18px;
  position:absolute;
  bottom: 1px;
  right: 1px;
  font: 16px sans-serif;
  text-align : center;
  line-height : 16px;
  border:1px solid #b4b3b3;
  color : #363539;
}

.maptalks-layer-switcher ul {
  list-style: none;
}
.maptalks-layer-switcher .panel > ul {
  padding-left: 1em;
}
.maptalks-layer-switcher .group > ul {
  padding-left: 10px;
}
.maptalks-layer-switcher .group + .group {
  padding-top: 1em;
}
.maptalks-layer-switcher label {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  font-size : 14px;
  white-space: nowrap;
  color: #5a5a5a;
}
.maptalks-layer-switcher .group > label {
  font-weight: bold;
  color: #5a5a5a;
  width: 100%;
}
.maptalks-layer-switcher .layer label {
  padding-top: 5px;
  width: 92%;
}
.maptalks-layer-switcher input {
  margin: 0 5px;
  position: relative;
  top: -2px;
}
.maptalks-layer-switcher input[disabled = 'disabled'] {
  cursor: not-allowed;
}
.maptalks-layer-switcher input[disabled = 'disabled'] + label {
  color: #666;
}
.maptalks-layer-switcher button, .maptalks-layer-switcher .panel {
  border-radius: 4px;
}
.maptalks-layer-switcher button {
  width: 28px;
  height: 28px;
  background: url(images/control/layer.svg) no-repeat;
  background-position: center;
  background-color: #fff;
  border: 1px solid #b8b8b8;
}
.maptalks-layer-switcher.shown button {
  display: none;
}
.maptalks-layer-switcher .panel {
  background-color: #fff;
  display: none;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 120px;
  max-width: 400px;
  max-height: 500px;
}
.maptalks-layer-switcher li {
  white-space:nowrap;
}
.maptalks-layer-switcher li.group {
  margin-right: 1em;
}
.maptalks-layer-switcher.shown .panel {
  display: block;
}
.maptalks-layer-switcher ::-webkit-scrollbar {
  width: 6px;
}
.maptalks-layer-switcher ::-webkit-scrollbar-track {
  background-color: #1f1f1f;
}
.maptalks-layer-switcher ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #777;
}
.maptalks-tooltip {
  display: block;
  background: #fff;
  border: 1px solid #b4b3b3;
  padding: 0px 4px 0px 4px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  white-space: nowrap;
}

@keyframes maptalksfadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.mtk-ui-fadein {
  animation: maptalksfadeIn 2s;
}
